<template>
  <vx-card title="Approval Credit Note">
    <vs-tabs>
      <vs-tab label="Open">
        <div class="tab-text">
          <open></open>
        </div>
      </vs-tab>
      <vs-tab label="Reversed">
        <div class="tab-text">
          <reversed></reversed>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Open from "./open/index.vue";
import Reversed from "./reversed/index.vue";

export default {
  components: {
    open: Open,
    reversed: Reversed,
  },
};
</script>
